import { createTheme } from "@mui/material";
import { vars } from "./variables";

const {
  chineseBlack,
  charlestonGreen,
  blackCoral,
  philippineSilver,
  rhythm,
  primaryFont,
  primaryActiveLight,
  grey,
  primary,
  secondary,
  lightGrey,
  white,
  secondaryFont,
} = vars;

const defaults = {
  primaryColor: {
    first: "#000000",
    second: "#FED500",
  },
  secondaryColor: {
    first: "#BE0203",
    second: "#002F5F",
  },
  fontFamily: {
    primary: primaryFont,
    secondary: secondaryFont,
  },
};

export const theme: any = (theme?: any) => {
  const { fontFamily = {}, primaryColor = {}, secondaryColor = {} } = theme;
  return {
    typography: {
      fontFamily: fontFamily?.primary ?? defaults?.fontFamily.primary,

      allVariants: {
        letterSpacing: "normal",
        lineHeight: "normal",
      },

      h1: {
        fontSize: "32px",
        fontWeight: 700,
        color: charlestonGreen,
      },

      h2: {
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "116.667%",
        color: chineseBlack,
      },

      h3: {
        fontSize: "20px",
        fontWeight: 400,
        color: charlestonGreen,
        lineHeight: "150%",
      },

      h4: {
        fontSize: "20px",
        fontWeight: 700,
        color: charlestonGreen,
        lineHeight: "140%",
      },

      h5: {
        fontSize: "18px",
        fontWeight: 700,
        color: chineseBlack,
        lineHeight: "155.556%",
      },

      h6: {
        fontSize: "16px",
        fontWeight: 700,
        color: rhythm,
      },

      body1: {
        fontSize: "16px",
        fontWeight: 500,
        color: chineseBlack,
      },

      body2: {
        fontSize: "16px",
        fontWeight: 400,
        color: rhythm,
      },

      subtitle1: {
        fontSize: "14px",
        fontWeight: 400,
        color: secondaryColor?.second ?? defaults?.secondaryColor?.second,
      },

      subtitle2: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "200%",
        color: chineseBlack,
      },

      caption: {
        fontSize: "13px",
        fontWeight: 400,
        color: philippineSilver,
        lineHeight: "160%",
      },

      overline: {
        fontSize: "13px",
        fontWeight: 400,
        color: blackCoral,
      },

      button: {},
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: `
          body {
            background: ${grey};
          }
          .MuiPickersMonth-monthButton.Mui-selected,
          .MuiPickersDay-root.Mui-selected {
            background-color: #682F76 !important;
          }
          
          .MuiPickersCalendarHeader-root .MuiIconButton-root {
            padding: 0;
            background: transparent;
            width: auto;
            height: auto;
          }
          .form-group + .form-group {
            margin-top: 24px
          }
          *, body {
            margin: 0;
            font-size: 16px;
            padding: 0;
            box-sizing: border-box;
            font-family: ${fontFamily?.primary ?? defaults?.fontFamily.primary};
          }
        `,
      },

      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: "1px !important",
            width: '16px'
          },
        },
      },

      MuiListItemText: {
        styleOverrides: {
          root: {
            margin: 0,
          },

          primary: {
            color: primary,
            fontSize: "14px",
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            columnGap: "12px",

            "&:before": {
              content: '""',
              width: "4px",
              height: "80%",
              position: "absolute",
              transform: "translateY(-50%)",
              background: "transparent",
              left: 0,
              borderRadius: "0 100px 100px 0",
              top: "50%",
              transition: "all ease-in-out .3s",
            },

            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: charlestonGreen,

              "&:before": {
                background: charlestonGreen,
              },

              "& .MuiListItemIcon-root": {
                color: charlestonGreen,
              },

              "& .MuiListItemText-primary": {
                color: charlestonGreen,
                fontWeight: 700,
              },

              "&:hover": {
                backgroundColor: "transparent",
              },
            },
          },
          gutters: {
            padding: "10px 24px",
          },
        },
      },

      MuiInput: {
        styleOverrides: {
          input: {},
          root: {
            "&:hover:not(.Mui-disabled, .Mui-error)::before": {
              borderColor: "#bababa",
              borderWidth: 1,
            },

            "&:after": {
              borderColor: "#00C385",
            },
            "&:before": {
              borderColor: "#bababa",
            },
          },
        },
      },

      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#ff0000",
          },
          root: {
            color: "#646E88",
            fontSize: "16px",
            fontWeight: 500,
            display: "block",
            marginBottom: "8px",
            lineHeight: "120%",

            "& a": {
              color: secondaryColor?.second ?? defaults?.secondaryColor?.second,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "normal",
              textDecorationLine: "underline",
            },

            "&.Mui-focused": {
              color: "#646E88",
            },
          },
        },
      },

      MuiInputAdornment: {
        styleOverrides: {
          root: {
            '& .MuiIconButton-root': {
              background: 'transparent',
              borderRadius: 0,
              padding: '0',
              width: 'auto',
              height: 'auto',
              marginRight: 0,
              '&:hover': {
                background: 'transparent'
              }
            },
            "& .MuiTypography-root": {
              color: "#5A6474",
              fontSize: "16px",
              lineHeight: "120%",
            },
          },
        },
      },

      MuiAvatarGroup: {
        styleOverrides: {
          root: {},
        },
      },

      MuiBackdrop: {
        styleOverrides: {
          root: {
            background: "rgba(0, 0, 0, 0.15)",
          },
        },
      },

      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius:'4px',
            backgroundColor: "rgb(231, 235, 249)",
            height: '24px',
          },
          label: {
            fontSize: "12px",
            fontWeight: 500,
          },
        },
      },

      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: "0 24px",
          },
        },
      },

      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            padding: "16px 24px",
          },
          content: {
            margin: 0,

            "& .MuiTypography-root": {
              color: "#646E88",
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "120%",
            },
          },
        },
      },

      MuiAccordion: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            "&:first-of-type": {
              borderRadius: 0,
            },
          },
        },
      },

      MuiBadge: {
        styleOverrides: {
          badge: {
            background: "#000",
          },

          dot: {
            border: "3px solid #fff",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
          },

          colorSuccess: {
            background: "green",
          },
        },
      },

      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: "90px",
            background: "rgba(0, 47, 95, 0.10)",
          },

          bar: {
            borderRadius: "90px",
            background: "#002F5F",
          },
        },
      },

      MuiFormHelperText: {
        styleOverrides: {
          root: {
            margin: 0,
            color: "#646E88",
            marginTop: "9.92px",
            fontSize: "14px",
            lineHeight: "120%",
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            color: primary,
            fontSize: "16px",
            lineHeight: "120%",
            paddingTop: '9px',
            paddingBottom: '9px',
            textOverflow: 'ellipsis',
            '&.MuiInputBase-inputMultiline': {
              padding: 0
            }
          },
          root: {
            background: "#fff",
            "&.Mui-focused": {
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: '#682f76',
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: '#682f76',
              },
            },
            "&:hover": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#DDE1EA",
              },
            },
          },
          notchedOutline: {
            borderColor: "#DDE1EA",
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: "1px",
            height: "48px",
            fontWeight: 700,
            borderRadius: "8px",
            fontSize: "16px",
            lineHeight: "1px",
            textTransform: "none",
            boxShadow: "none",
            // letterSpacing: '0.64px',
            padding: "0 26px",
            color: "#0A4DFF",
          },

          outlinedError: {
            borderColor: "#BE0203",
            color: "#BE0203",

            "&:hover": {
              borderColor: "#BE0203",
              color: "#BE0203",
              background: "#FFE6E6",
            },
          },

          containedError: {
            background: "#BE0203",
            boxShadow:
              "0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset",
            color: white,

            "&:hover": {
              background: "#9F0405",
              boxShadow: "none",
            },
          },

          outlinedPrimary: {
            borderColor: "#682F76",
            color: "#682F76",

            "&:hover": {
              borderColor: "#682F76",
              color: "#682F76",
              background: "#FCEFFF",
            },
          },

          containedPrimary: {
            background: "#682F76",
            boxShadow:
              "0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset",
            color: white,

            "&:hover": {
              background: "#542460",
              boxShadow: "none",
            },
          },

          containedSecondary: {
            background: "#000",
            boxShadow:
              "0px 6px 2px -4px rgba(14, 14, 44, 0.10), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset",
            color: white,

            "&:hover": {
              background: "#000",
              boxShadow: "none",
            },
          },
        },
      },

      MuiFormControl: {
        styleOverrides: {
          root: {
            "& .MuiFormLabel-root": {
              justifyContent: "flex-start",
            },
          },
        },
      },

      MuiFormGroup: {
        styleOverrides: {
          root: {
            gap: "16px",
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
            padding: 0,
            justifyContent: "space-between",
          },
          label: {
            color: "#5A6474",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "normal",
          },
        },
      },

      MuiButtonGroup: {
        styleOverrides: {
          grouped: {
            '&:not(:last-of-type)': {
              borderColor: 'transparent'
            }
          }
        }
      },

      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,

            '&.Mui-checked': {
              color: '#000'
            },

            "& + .MuiFormControlLabel-label": {
              marginLeft: "8px",
            },
          },
        },
      },

      MuiCardHeader: {
        styleOverrides: {
          root: {
            borderBottom: "1px solid #ECECEC",
          },
          action: {
            margin: 0,
          },
          title: {
            color: secondary,
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "28px",
          },
        },
      },

      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            background: white,
            boxShadow: "none",
          },
        },
      },

      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: "#5A6474",
            fontSize: "14px",
            fontWeight: 500,

            "&.Mui-disabled": {
              display: "none",
            },

            "&.Mui-selected": {
              color: charlestonGreen,
              background: primaryActiveLight,

              "&:hover": {
                color: charlestonGreen,
                background: primaryActiveLight,
              },
            },
          },
        },
      },

      MuiTablePagination: {
        styleOverrides: {
          menuItem: {
            color: secondary,
            "&.Mui-selected": {
              backgroundColor: primaryActiveLight,
              color: charlestonGreen,
              "&:hover": {
                backgroundColor: primaryActiveLight,
                color: charlestonGreen,
              },
              "&:focus": {
                backgroundColor: primaryActiveLight,
                color: charlestonGreen,
              },
            },
          },
          actions: {
            "& .MuiIconButton-root": {
              width: "auto",
              height: "auto",
              background: "transparent",
            },
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          track: {
            boxShadow: "0px 2px 2px -1px rgba(74, 74, 104, 0.10) inset",
            borderRadius: "24px",
            opacity: 1,
            background: "#ECF1F4",
          },
          input: {
            width: "100%",
            left: 0,
          },
          thumb: {
            width: 12,
            height: 12,
            // boxShadow: '0px 5.5008px 5px -3px rgba(14, 14, 44, 0.20), 0px -1px 0px 0px rgba(14, 14, 44, 0.40) inset',
          },
          switchBase: {
            // width: '100%',
            // height: '100%',
            // boxShadow: 'none',
            padding: "0",
            transform: "translateX(4px) translateY(3.5008px)",

            "&.Mui-checked": {
              transform: "translateX(20px) translateY(3.5008px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                background: "#000",
                opacity: 1,
              },
            },
          },
          root: {
            padding: 0,
            width: "36px",
            height: "20px",
          },
        },
      },

      MuiIconButton: {
        styleOverrides: {
          root: {
            background: grey,
            borderRadius: "8px",
            width: "42px",
            height: "42px",
          },

          colorPrimary: {
            background: charlestonGreen,
            "&:hover": {
              background: charlestonGreen,
            },
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: {},
        },
      },

      MuiTable: {
        styleOverrides: {
          root: {
            // borderCollapse: 'separate',
            // borderSpacing: '0 6px'
          },
        },
      },
      
      

      MuiTableCell: {
        styleOverrides: {
          body: {
            background: '#fff',
            "&:first-of-type": {
              borderLeft: '1px solid rgb(224, 224, 224)',
            },

            "&:last-of-type": {
              borderRight: '1px solid rgb(224, 224, 224)',
            }
          },
          head: {
            color: "#595858",
            background: '#e5e5e5',
            borderBottom: 'none !important',
            "&:first-of-type": {
              borderTopLeftRadius: "0",
            },

            "&:last-of-type": {
              borderTopRightRadius: "0",
            }
          },
          root: {
            fontSize: "14px",
            lineHeight: "normal",
            padding: '12px',
            borderBottom: '1px solid rgb(224, 224, 224)',

            "&:first-of-type": {
              borderLeft: '1px solid rgb(224, 224, 224)',
              borderRight: '1px solid rgb(224, 224, 224)',
            },

            "&:last-of-type": {
              borderRight: '1px solid rgb(224, 224, 224)',
            },
              
            "&:not(:nth-child(2)):last-of-type": {
              borderLeft: '1px solid rgb(224, 224, 224)',
            }
          },
        },
      },

      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: "currentColor",
          },
          root: {
            fontSize: '14px',
            "&.Mui-active": {
              color: "#5A6474",
              fontWeight: 500,
            },
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          head: {},
          root: {
            "&:last-of-type": {
              '& td': {
                borderBottom: '1px solid rgba(224, 224, 224, 1) !important',

                "&:first-of-type": {
                  borderLeft: '1px solid rgb(224, 224, 224)',
                },

                "&:last-of-type": {
                  borderRight: '1px solid rgb(224, 224, 224)',
                }
              },
            },

            "&.MuiTableRow-hover": {
              "&:hover": {
                backgroundColor: "transparent",
              },
            },

            "&.Mui-selected": {
              backgroundColor: primaryActiveLight,
              "&:hover": {
                backgroundColor: primaryActiveLight,
              },
            },
          },
        },
      },

      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.MuiCheckbox-indeterminate": {
              color: charlestonGreen,
            },
            "&.Mui-checked": {
              color: charlestonGreen,
            },
          },
        },
      },

      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: "677px",
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '114.286%',
            padding: '6px 12px',
            minHeight: '32px',
            minWidth: '1px',
            color: "#5A6474",
            boxSizing: 'border-box',
            background: '#fff',
            borderRadius: '4px',
            border: '1px solid transparent',
            transition: 'all ease-in-out .3s',

            '&.Mui-selected': {
              borderColor: secondaryColor?.second ?? defaults?.secondaryColor?.second,
              color: secondaryColor?.second ?? defaults?.secondaryColor?.second
            }
          }
        }
      },

      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: 'none'
          },
          flexContainer: {
            gap: '8px'
          },

          root: {
            minHeight: '32px',
            '&.secondary': {
              '& .MuiTabs-indicator': {
                display: 'block',
                background: secondaryColor?.second ?? defaults?.secondaryColor?.second
              },
              '& .MuiTabs-flexContainer': {
                borderBottom: `1px solid #D2D2D2`,
                gap: '1px',
                '& .MuiTab-root': {
                  background: 'transparent',
                  color: "#5A6474",
                  borderRadius: '0',
                  border: 'none',
                  '&.Mui-selected': {
                    color: secondaryColor?.second ?? defaults?.secondaryColor?.second
                  }
                }
              }
            }
          }
        }
      },

      MuiCardMedia: {
        styleOverrides: {
          root: {
            border: '1px solid #ECECEC !important'
          }
        }
      },
    },
  };
};