import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React, { FC, useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo from "../../images/logo.svg";
import userLogo from "../../images/sidebar-userlogo.png";
import { AppContext } from "../../App";
import { Home, NavIcon, SidebarLogout } from "../../components/Icon";
import { Text } from "../../components/Text";

interface ISidebar {
  toggle: boolean;
  setToggle: Function;
  logoUrl: string;
  menuArr: any[];
}

export const Sidebar: FC<ISidebar> = ({
  toggle,
  setToggle,
  logoUrl,
  menuArr,
}) => {
  const [open, setOpen] = React.useState("");
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };


  const classes = {
    root: {
      backgroundColor: "white",
      flexShrink: 0,
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },

    header: {
      flexShrink: 0,
      height: "4.5rem",
      padding: toggle ? "0 1rem" : '0 0.75rem',
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "center",
      "& img": {
        height: "2.375rem",
        background: "#000000",
      },

    },

    body: {
      overflowY: "auto",
      overglowX: "hidden",
      flexGrow: 1,
      "&::-webkit-scrollbar": { width: "0.3125rem" },
      "::-webkit-scrollbar-track": { background: "transparent" },
      "::-webkit-scrollbar-thumb": { background: "#5c504a" },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#5c504a",
      },
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  };
  const handleItemClick = (item: any) => {
    if (item.external) {
      window.open(item.link, "_blank");
    }
  };
  return (
    <Box
      sx={{
        ...classes.root,
        width: toggle ? "16.875rem" : "4rem",
        display: "flex",
        flexDirection: "column",
        transition: "all ease-in-out .3s",
        borderRight: "0.0625rem solid rgba(9,36,75, 0.2) !important",
        boxShadow: '0 0 1.875rem 0 rgba(0,0,0,0.1)'
      }}
    >
      <Box sx={classes.header}>
        {toggle && <img src={logo} alt="logo" />}
        <IconButton
          sx={{
            background: "transparent",
            color: "black",
            transition: "100ms ease all",
            ":hover": {
              background: "#F4F5F7",
              color: "black",
            },
          }}
          onClick={() => setToggle((prev: boolean) => !prev)}
        >
          <NavIcon />
        </IconButton>
        {/* {!toggle ? (
          
        ) : (
          
        )} */}
      </Box>

      <Box sx={classes.body}>
        {menuArr?.map((menu: any) => (
          <Box key={menu?.id} sx={{ px: 2 }} >
            <List
              component="nav"
            >
              {menu?.data?.map((item: any, idx: number) => {
                const selected = location?.pathname === item?.link;
                if (item?.child) {
                  return (
                    <>
                      <ListItemButton
                        selected={open?.includes(item?.id)}
                        key={`list-button-${idx + 1}`}
                        sx={{ color: "#8491A5" }}

                        onClick={() => {
                          if (!toggle) {
                            setToggle(true);
                          }
                          setOpen(() =>
                            open?.includes(item?.id) ? "" : item?.id
                          );
                        }}
                      >

                        <ListItemIcon>
                          <item.icon color="#8491A5" />
                        </ListItemIcon>
                        {toggle && (
                          <>
                            <ListItemText primary={item?.text} />
                            <Home
                              color="#8491A5"
                              style={{
                                transform: open?.includes(item?.id)
                                  ? "rotate(90deg)"
                                  : "rotate(0deg)",
                              }}
                            />
                          </>
                        )}
                      </ListItemButton>

                      {toggle && (
                        <Box px={3}>
                          <Collapse
                            in={open?.includes(item?.id)}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div">
                              {item?.child?.map((child: any) => (
                                <ListItemButton
                                  selected={location?.pathname === child?.link}
                                  disabled={!child.active}
                                  component={Link}
                                  key={child?.id}
                                  to={child?.link}
                                  sx={{
                                    pl: 4,
                                    borderRadius: 2,
                                    "&.Mui-selected": {
                                      backgroundolor: "red",
                                      "& .MuiListItemText-primary": {
                                        fontWeight: 400,
                                      },
                                      "&:before": {
                                        display: "none",
                                      },
                                    },
                                  }}
                                >
                                  <ListItemText primary={child?.text} />
                                </ListItemButton>
                              ))}
                            </List>
                          </Collapse>
                        </Box>
                      )}
                    </>
                  );
                }
                return (
                  <Tooltip title={toggle ? '' : item?.text} arrow placement="left">
                    <ListItemButton
                      component={item.external ? 'div' : Link}
                      onClick={() => {
                        handleItemClick(item);
                        setToggle(true);
                        
                      }}
                      to={item.external ? undefined : item?.link}
                      selected={selected}
                      sx={{
                        borderRadius: "0.5rem",
                        padding: toggle ? "0.75rem 1rem" : "0.5rem",
                        color: "#8491A5",

                        '&:not(:first-child)': {
                          marginTop: '0.5rem'
                        },

                        "&.Mui-selected": {
                          backgroundColor: "#682f76",

                          "& .MuiListItemText-primary": {
                            fontWeight: 600,
                            color: "#fff",
                          },
                          ":hover": {
                            background: "#682f76",
                            color: "black",

                          },
                          "::before": {
                            display: "none"
                          }
                        },
                      }}
                      disabled={!item.active}
                      key={item?.id}
                    >
                      <ListItemIcon>
                        <item.icon color={selected ? "#ffffff" : "#8491A5"} />
                      </ListItemIcon>
                      {toggle && (
                        <ListItemText
                          primaryTypographyProps={{
                            fontWeight: 500,
                            color: "#8491A5",

                          }}
                          sx={{ whiteSpace: "nowrap", color: "#000000" }}
                          primary={item?.text}

                        />
                      )}
                    </ListItemButton>
                  </Tooltip>
                );
              })}
            </List>
          </Box>
        ))}
      </Box>

      <Box sx={{ backgroundColor: "#F4F5F7", width: "100%", flexShrink: 0, padding: toggle ? "1rem 1rem" : "1rem 0.75rem" , display:"flex", justifyContent:"space-between", alignItems:"center" }} >
        {toggle && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }} >
            <img src={userLogo} width={40} alt="" />
            <Box sx={{  }} >
              <Text sx={{ fontSize: "0.875rem", fontWeight: "500" }} >
                Richyard
              </Text>
              <Text sx={{ fontSize: "0.875rem", fontWeight: "400", color: "#8491A5", }} >
                9394lay@gmail.com
              </Text>
            </Box>
          </Box>
        )}

        <IconButton>
          <SidebarLogout />
        </IconButton>
      </Box>
    </Box>
  );
};
