import { Formik, Form as FormikForm } from "formik";
import * as React from "react";
import { FC } from "react";

export const Form: FC<any> = ({
  initialValues = {},
  onSubmit,
  children,
  validationSchema,
  className,
  style,
  render,
  formRef,
  validate,
}) => {
  return (
    <Formik
      {...(validate ? { validate } : {})}
      innerRef={formRef}
      validateOnChange
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, ...rest }) => (
        <FormikForm onSubmit={handleSubmit} className={className} style={style}>
          {render ? render({ ...rest }) : children}
        </FormikForm>
      )}
    </Formik>
  );
};
