import * as routePaths from "./RouteConstant";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import React, { Component, Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";
import { Otp } from "./pages/Register/otp";
import RegisterWrapper from "./components/RegisterWrapper";


const PageNotFound = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.PageNotFound,
  }))
);

const AdminDashboard = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AdminDashboard,
  }))
);
const Event = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Events,
  }))
);

const Clubs = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Clubs,
  }))
);

const Partners = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Partners,
  }))
);

const Careers = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Careers,
  }))
);

const Media = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Media,
  }))
);

const Login = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Login,
  }))
);

const otp = React.lazy(() =>
  import("./pages/Register/otp").then((module) => ({
    default: module.Otp,
  }))
);

const Register = React.lazy(() =>
  import("./pages/Register/register").then((module) => ({
    default: module.Register,
  }))
);

const ResetEmailPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ResetEmailPage,
  }))
);
const ChangePasswordPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ChangePasswordPage,
  }))
);
const CategoryListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.CategoryList,
  }))
);
const CategoryAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddEditCategory,
  }))
);
const ProductListPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.ProductList,
  }))
);
const ProductAddPage = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.AddEditProduct,
  }))
);


const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoggedIn = !!state?.user;
  const isLoading = state?.app_loading;
  console.log("hello" + component)
  const location = useLocation();
  if (location.pathname === routePaths.OTP) {
    return <Suspense fallback={"Loading"}>{component}</Suspense>;
  }
  if (isLoading) return null;

  // if (!isLoggedIn) {
  //   return (
  //     <Navigate to={routePaths.LOGIN + "?redirect_to=" + location.pathname} />
  //   );
  // }
  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={"Loading"}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

const PublicRoutes = ({ component }: any) => {
  return <Suspense fallback={"Loading"}>{component}</Suspense>;
};
export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<AdminDashboard />} />,
      },
      {
        path: routePaths.EVENT,
        element: <AuthRoutes component={<Event />} />,
      },
      {
        path: routePaths.CLUBS,
        element: <AuthRoutes component={<Clubs />} />,
      },
      {
        path: routePaths.PARTNERS,
        element: <AuthRoutes component={<Partners />} />,
      },
      {
        path: routePaths.CAREERS,
        element: <AuthRoutes component={<Careers />} />,
      },
      {
        path: routePaths.MEDIA,
        element: <AuthRoutes component={<Media />} />,
      },
      {
        path: routePaths.LOGIN,
        element: <PublicRoutes component={<Login />} />,
      },
      {
        path: routePaths.REGISTER,
        element: <PublicRoutes component={<Register />} />,
      },
      {
        path: routePaths.OTP,
        element: <PublicRoutes component={<Otp />} />,
      },
      {
        path: routePaths.FORGOT_PASSWORD,
        element: <PublicRoutes component={<ResetEmailPage />} />,
      },
      {
        path: routePaths.RESET_PASSWORD,
        element: <PublicRoutes component={<ChangePasswordPage />} />,
      },
      {
        path: routePaths.CATEGORY_LIST,
        element: <PublicRoutes component={<CategoryListPage />} />,
      },
      {
        path: routePaths.CATEGORY_ADD,
        element: <PublicRoutes component={<CategoryAddPage />} />,
      },
      {
        path: routePaths.CATEGORY_EDIT,
        element: <PublicRoutes component={<CategoryAddPage />} />,
      },
      {
        path: routePaths.PRODUCT_LIST,
        element: <PublicRoutes component={<ProductListPage />} />,
      },
      {
        path: routePaths.PRODUCT_ADD,
        element: <PublicRoutes component={<ProductAddPage />} />,
      },
      {
        path: routePaths.PRODUCT_EDIT,
        element: <PublicRoutes component={<ProductAddPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={"Loading"}>
        <PageNotFound />
      </Suspense>
    ),
  },
];
