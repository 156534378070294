import "react-toastify/dist/ReactToastify.css";
import { useRoutes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "./routes";
import { createContext, useContext, useEffect, useReducer } from "react";
import { ActionType, reducer } from "./Reducer";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { me, setToken } from "./Services/Service";
import { useIdleTimer } from 'react-idle-timer';
import { CustomTheme } from "./components/Theme";

const initialState = {
  state: {
    app_loading: true,
    customTheme: {
      primaryColor: {
        first: "#000000",
        second: "#FED500",
      },
      secondaryColor: {
        first: "#BE0203",
        second: "#002F5F",
      },
    },
  },
  dispatch: () => {},
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);

const Main = () => {
  const { dispatch } = useContext<any>(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // auto login user if token exists
    const token = localStorage.getItem("token");
    console.log(token);
    if (token) {
      setToken(token)
      me()
        .then(({ data }) => {
          dispatch({
            type: ActionType.GET_USER_SUCCESS,
            payload: data.data,
          });

          dispatch({
            type: ActionType.SET_APP_LOADING_FLAG,
            payload: false,
          });

          if (location) {
            navigate(location.pathname);
          }
        })
        .catch((err: any) => {
          dispatch({
            type: ActionType.SET_APP_LOADING_FLAG,
            payload: false,
          });
        });
    } else {
      dispatch({
        type: ActionType.SET_APP_LOADING_FLAG,
        payload: false,
      });
    }
  }, []);

  const routing = useRoutes(routes);

  return <>{routing}</>;
};

const App = ({ config }: any) => {
  const onIdle = () => {
    localStorage.clear();
    window.location.reload();
    //insert any custom logout logic here
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000, //10 minute idle timeout
  })
  console.log(getRemainingTime)
 
  const [state, dispatch] = useReducer(reducer, initialState.state);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <CustomTheme customTheme={state?.customTheme}>
        <Main />
        <ToastContainer />
      </CustomTheme>
    </AppContext.Provider>
  );
};

export default App;
