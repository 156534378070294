import { Button as CustomButton, CircularProgress } from "@mui/material"
import React from "react";


export interface IButton {
  variant?: "text" | "outlined" | "contained";
  onClick?: () => void;
  children: React.ReactNode;
  size?: "large" | "medium" | "small";
  disabled?: boolean;
  fullWidth?: boolean;
  [x: string]: any;
}


export const Button: React.FC<IButton> = (props) => {
  const { variant = "contained", size = "medium", fullWidth = false, onClick, children, disabled, loading } = props;
  const progressSize = {
    "small" : 18,
    "medium" : 18,
    "large" : 20,
  }

  return (
    <CustomButton
      {...props}
      size={size}
      fullWidth={fullWidth}
      disableElevation
      disabled={disabled || loading}
      onClick={onClick}
      variant={variant}
    >
      {!loading ? (
        children
      ) : (
        <CircularProgress size={progressSize[size]} sx={{ color: "gray" }} />
      )}
    </CustomButton>
  );
}