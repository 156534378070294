import {
  Box,
  FormControl,
  FormLabel,
  InputProps,
  TextField,
  TextFieldProps,
  TextFieldVariants,
} from "@mui/material";
import React, { ReactNode } from "react";
import { useField, useFormikContext } from "formik";

export interface InputBoxProps {
  id?: string;
  rows?: number;
  sx?: Object;
  label?: string;
  error?: boolean;
  maxRows?: number;
  className?: string;
  fullWidth?: boolean;
  focused?: boolean;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  defaultValue?: string;
  variant?: TextFieldVariants;
  type?: string;
  InputProps?: InputProps;
  color?: "error" | "primary" | "secondary" | "info" | "success" | "warning";
  value?: any;
  name?: any;
  customLink?: ReactNode;
  [x: string]: any;
}

export const InputBox: React.FC<InputBoxProps & TextFieldProps> = ({
  className,
  onChange,
  label,
  variant,
  id,
  fullWidth,
  helperText,
  sx,
  multiline,
  maxRows,
  rows,
  error,
  placeholder,
  defaultValue,
  type,
  InputProps,
  color,
  required,
  disabled,
  name,
  value,
  autoComplete,
  spellCheck,
  customSpacing = 0.5,
  customLink,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name, type });
  const { submitCount } = useFormikContext();
  const showError = (submitCount > 0 || meta.touched) && meta.error;
  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <Box
          sx={{
            mb: customSpacing,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between !important",
          }}
        >
          <FormLabel required={required}>{label}</FormLabel>
          {customLink && customLink}
        </Box>
      )}
      <TextField
        {...field}
        {...rest}
        id={id}
        type={type}
        color={color}
        error={showError ? true : false}
        sx={sx}
        variant={variant}
        spellCheck={spellCheck}
        disabled={disabled}
        required={required}
        autoComplete={autoComplete}
        onChange={(e) => {
          !meta.touched && helpers.setTouched(true);
          onChange?.(e);
          field?.onChange(e);
        }}
        fullWidth={fullWidth}
        className={`${className} ${showError ? "false" : ""}`}
        multiline={multiline}
        helperText={helperText ?? (meta.touched ? meta.error : "")}
        InputProps={InputProps}
        placeholder={placeholder}
        rows={multiline ? rows : 0}
        defaultValue={defaultValue}
        maxRows={multiline ? maxRows : 0}
        value={value ?? field.value ?? ""}
      />
    </FormControl>
  );
};

InputBox.defaultProps = {
  fullWidth: true,
  multiline: false,
  maxRows: 0,
  rows: 0,
  error: false,
  type: "text",
  color: "primary",
  required: false,
  disabled: false,
  autoComplete: "off",
  spellCheck: false,
};
