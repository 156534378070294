import { Box, Grid } from "@mui/material";
import { Text } from "../../components/Text";
import { Form } from "../../components/Form";
import { InputBox } from "../../components/TextField";
import { Button } from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import * as routePaths from "../../RouteConstant";
import RegisterWrapper from "../../components/RegisterWrapper";
import { otpValidationSchema } from "./Validation";

export const authStyles = {
  background: "#fff",
  "& img": {
    background: "#000000",
  },
};

export const Otp = () => {
  const { state } = useContext<any>(AppContext);
  let navigate = useNavigate();

  useEffect(() => {
    console.log("otp sent succecfully")
  }, []);

  return (
    <RegisterWrapper>
      <>
        <Box textAlign={"left"} ml={2}>
          <Text sx={{ fontSize: "30px", color: "#000" }}>
            Welcome To Rest the Case
          </Text>
        </Box>
        <Box textAlign={"left"} ml={2}>
          <Text sx={{ fontSize: "18px", color: "#A7A8BB" }}>
            Enter OTP sent to {state?.user?.email}
          </Text>
        </Box>
        <Box sx={{ marginTop: "25px", p: 2 }}>
          <Grid height={1} container spacing={0}>
            <Grid height={1} item md={12}>
              <Box
                height={1}
                px={0}
                pt={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box flexGrow={1}>
                  <Form
                    validationSchema={otpValidationSchema}
                    initialValues={{
                      otp: "",
                    }}
                    onSubmit={async (data: any) =>
                     console.log("otp submited")
                    }
                    render={({ handleChange, isValid }: any) => (
                      <Box display="flex" flexDirection="column" gap={3}>
                        <InputBox
                          onChange={handleChange}
                          id="otp"
                          name="otp"
                          type="number"
                          label="Otp"
                          placeholder="Enter First Name"
                          customSpacing={0}
                          required
                          size="small"
                        />
                        <Button
                          disabled={!isValid}
                          variant="contained"
                          color="secondary"
                          type="submit"
                          sx={{ backgroundColor: "#f68122", color: "#fff" , "&:hover": {backgroundColor:"#c9620e"}  }}
                        >
                          Verify OTP
                        </Button>
                      </Box>
                    )}
                  ></Form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    </RegisterWrapper>
  );
};
