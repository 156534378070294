import * as routePaths from "../../RouteConstant";
import { Category, Product } from "../../components/Icon";
import { Blogs, Brands, Career, CaseStudies, Clubs, Events, Home, Media } from "../../components/Icon";

export const MenuArr = [
  {
    id: "0",
    data: [
      {
        id: "0.1",
        icon: Home,
        text: "Dashboard",
        active: true,
        link: routePaths.DASHBOARD,
      },
      {
        id: "0.2",  
        icon: Events,
        text: "Events",
        active: true,
        link: routePaths.EVENT,
      },
      {
        id: "0.3",
        icon: Career,
        text: "Careers",
        active: true,
        link: routePaths.CAREERS,
      },
      {
        id: "0.4",
        icon: Brands,
        text: "Brands",
        active: true,
        link: routePaths.PARTNERS,
      },
      {
        id: "0.5",
        icon: Media,
        text: "Media and PR",
        active: true,
        link: routePaths.MEDIA,
      },
      {
        id: "0.6",
        icon: Blogs,
        text: "Blog",
        active: false,
      },
      {
        id: "0.7",
        icon: Clubs,
        text: "Clubs",
        active: true,
        link: routePaths.CLUBS,
      },
      {
        id: "0.8",
        icon: CaseStudies,
        text: "Case Studies",
        active: false,
      },
     
    ],
  },
];
