import React, { PropsWithChildren } from 'react'
import Register from "../images/boy.svg";
import Rest from "../images/logo.svg";
import { Box } from "@mui/material";

export const authStyles = {
    background: "#fff",
    // "& img": {
    //   background: "#000000",
    // },
  };

const RegisterWrapper:React.FC<PropsWithChildren> = ({children}) => {
  return (
    <Box sx={authStyles} p={0} height="100vh">
      <Box display={'flex'}  >
        <Box flex={1} >
          <img src={Register} style={{ height: '100%', width: '100%', objectFit: 'cover' }} alt="lady" />
        </Box>
        <Box flex={1} sx={{ background: '#1c1c1b', }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' , backgroundColor:"#ffffff" }}>
            <Box display={'block'} sx={{width:'500px'}}>
              <Box textAlign={'center'}>
                <img src={Rest} style={{ width: '220px', objectFit: 'cover', margin:'20px' }} alt="rest" />
              </Box>
              {children}
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  )
}

export default RegisterWrapper