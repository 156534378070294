import { CssBaseline, ThemeProvider, createTheme } from "@mui/material"
import React, { ReactNode } from "react"
import { theme } from "./theme";
import { vars } from "./variables";

const { primaryFont, secondaryFont } = vars

export interface PrimaryColor {
  first?: string
  second?: string
}

export interface SecondaryColor {
  first?: string
  second?: string
}

export interface FontFamily {
  primary?: string
  secondary?: string
}

interface customTheme {
  primaryColor?: PrimaryColor
  secondaryColor?: SecondaryColor
  fontFamily?: FontFamily
}

interface ICustomTheme {
  customTheme?: customTheme;
  children: ReactNode;
}

export const CustomTheme: React.FC<ICustomTheme> = ({ customTheme = {}, children }) => {
  return (
    <ThemeProvider theme={createTheme(theme(customTheme))}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
};