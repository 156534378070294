import { ReactNode, useContext, useState } from "react";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import {
  MenuArr,
} from "../Sidebar/constant";
import { AppContext } from "../../App";
import { ComponentWrapper } from "../../components/ComponentWrapper";

interface IComponentWrapper {
  children: ReactNode;
}

function modulePresent(arr1: any, arr2: any) {
  for (const element of arr1) {
    if (arr2.has(element)) {
      return true;
    }
  }

  return false;
}

const filterMenuByPermission = (user: any) => {

  if (!user == true) {
    return MenuArr;
  }


  // filter main menu by permission
  let filteredMenu = MenuArr.map((mainMenu: any) => {
    mainMenu.data = mainMenu.data.filter((menuItem: any) => {
    console.log("hello") 
    });
    return mainMenu;
  });

  //filter submenu by permission
  filteredMenu = filteredMenu.map((mainMenu: any) => {
    mainMenu.data = mainMenu.data.map((menuItem: any) => {
      if (menuItem?.child) {
        //filter child
        menuItem.child = menuItem.child.filter((child: any) =>
          console.log("hello")
        );
      }
      return menuItem;
    });
    return mainMenu;
  });

  return filteredMenu;
};

export const Layout: React.FC<IComponentWrapper> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const { state } = useContext<any>(AppContext);
  let menu = filterMenuByPermission(state.user);
  return (
    <ComponentWrapper
      Sidebar={
        <Sidebar
          toggle={open}
          setToggle={setOpen}
          logoUrl={
            "https://dev.restthecase.com/storage/uploads/setting/1697609250logo.png"
          }
          menuArr={menu}
        />
      }
    >
      {children}
    </ComponentWrapper>
  );
};
