export const vars = {
  primaryFont: "'DM Sans', sans-serif",
  secondaryFont: "'Epilogue', sans- serif",
  chineseBlack: "#121212",
  charlestonGreen: "#282730",
  blackCoral: "#5A6474",
  philippineSilver: "#B8B8B8",
  rhythm: "#726E89",


  // TODO: REMOVE LATER
  primary: "#5A6474",
  primaryActive: "#0A4DFF",
  primaryActiveLight: "#EEF3FF",
  secondary: "#121212",
  grey: "#F4F5F7",
  lightGrey: "#B8B8B8",
  white: "#fff",
};
