export const DASHBOARD = "/";
export const LOGIN = "/login";
export const EVENT = "/event";
export const CLUBS = "/clubs";
export const PARTNERS = "/partners";
export const CAREERS = "/careers";
export const MEDIA = "/media";
export const REGISTER = "/register";
export const OTP = "/otp";
export const FORGOT_PASSWORD = "/forgot-password";
export const LEAD = "/leads";
export const ASSETS = "/assets-websites";
export const RESET_PASSWORD = "/reset-password";
export const CATEGORY_LIST = "/category";
export const CATEGORY_ADD = "/category/create";
export const CATEGORY_EDIT = "/category/edit/:id";
export const PRODUCT_LIST = "/product";
export const PRODUCT_ADD = "/product/create";
export const PRODUCT_EDIT = "/product/edit/:id";
