import * as Yup from "yup";

export const registerValidationSchema = Yup.object().shape({
  email: Yup.string().required("First name is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  phone: Yup.string()
    .matches(/^[0-9]\d{9}$/, "Invalid mobile number").required("Phone number is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], "Password must match"),
});
export const otpValidationSchema = Yup.object().shape({
  otp: Yup.string().matches(/^\d{4}$/, "Invalid OTP").required("Otp is required"),
});
