import { Box } from "@mui/material";
import type { ReactNode } from "react";
import React from "react";
import { Sidebar } from "../../shared/Sidebar";

interface IComponentWrapper {
  children: ReactNode;
  Sidebar: ReactNode;
}

export const ComponentWrapper: React.FC<IComponentWrapper> = ({
  children,
  Sidebar
}: any) => {
  return (
    <Box height="100vh" display="flex">
      {Sidebar}
      <Box flexGrow={1} overflow="auto">
        
        <Box p={3}>{children}</Box>
      </Box>
    </Box>
  );
};
